import React from "react";
import Link from "gatsby-plugin-transition-link";

const Offcanvas = () => (
  <div
    id="sidebar"
    className="uk-offcanvas uk-hidden@m uk-offcanvas-overlay"
    data-uk-offcanvas
  >
    <div className="uk-offcanvas-bar uk-offcanvas-bar-animation sidebarmob">
      <div className="sidebarlinkdiv">
        <p className="sidebarlinks">
          <Link to="/" activeClassName="uk-text-bold">
            Home
          </Link>
        </p>
        <p className="sidebarlinks">
          <Link to="/work/" activeClassName="uk-text-bold">
            Work
          </Link>
        </p>
        <p className="sidebarlinks">
          <Link to="/blog/" activeClassName="uk-text-bold">
            Blog
          </Link>
        </p>
        <p className="sidebarlinks">
          <Link to="/contact/" activeClassName="uk-text-bold">
            Contact
          </Link>
        </p>
      </div>
    </div>
  </div>
);

export default Offcanvas;
