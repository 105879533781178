import React from "react";
import PropTypes from "prop-types";

import logo from "../images/logo.png";

const Header = () => (
  <div>
    <header className="topbar">
      <div className="uk-container container">
        <div className="uk-flex uk-flex-between uk-flex-middle topbarcontent">
          <a href="https://lifeplaninvestments.ca">
            <img id="logo" className="uk-logo" src={logo} alt="logo" />
          </a>
          <div className="topbarcenter">
            <p>
              Have questions? Call us at <span>(905) 294 - 7526</span>
            </p>
          </div>
          <div className="topbarright">
            <a href="https://lifeplaninvestments.ca/onboarding">
              Start a new quote
            </a>
          </div>
        </div>
      </div>
    </header>
  </div>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
