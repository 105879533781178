/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, title, siteUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaUrl = siteUrl || site.siteMetadata.siteUrl;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      //titleTemplate={`%s | ${site.siteMetadata.title}`}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: `LifePlan Investments`,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:site`,
          content: `@LifePlanCanada`,
        },
        {
          name: `twitter:creator`,
          content: `@LifePlanCanada`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image:alt`,
          content: `LifePlan Investments`,
        },
      ].concat(meta)}
    >
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossorigin
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,600&display=swap"
        rel="stylesheet"
      ></link>
      <script type="application/ld+json">{`
        {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "LifePlan Investments",
            "url": "https://apply.lifeplaninvestments.ca",
            "logo": "https://apply.lifeplaninvestments.ca/logo.png",
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+19052947526",
                "contactType": "customer service"
            },
            "sameAs": [
                "https://www.facebook.com/LifePlanCanada/",
                "https://twitter.com/LifePlanCanada",
                "https://www.instagram.com/lifeplaninvestments/",
                "https://ca.linkedin.com/company/lifeplaninvestments"
            ]
        }
    `}</script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  siteUrl: PropTypes.string,
};

export default SEO;
