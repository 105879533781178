import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

//import HeaderPromo from "./HeaderPromo";
import Header from "./Header";
import Offcanvas from "./Offcanvas";

import "../../node_modules/uikit/dist/css/uikit.min.css";
import "./layout.css";

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <TemplateWrapper
      data={data}
      location={location}
      children={children}
    ></TemplateWrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.node.isRequired,
};

class TemplateWrapper extends React.Component {
  componentDidMount() {
    try {
      this.UIkit = require("uikit/dist/js/uikit");
      this.Icons = require("uikit/dist/js/uikit-icons");
      this.UIkit.use(this.Icons);
    } catch (e) {
      console.error(e);
    }
  }
  render() {
    let header;
    /*if (this.props.location.pathname === "/markham-website-design") {
      header = <HeaderLanding />;
    } else {
      header = <Header />;
    } */

    switch (this.props.location.pathname) {
      case "/confidential/":
        header = <Header />;
        break;
      default:
        header = <Header />;
        break;
    }
    return (
      <div>
        <div className="uk-offcanvas-content">
          {/*<HeaderPromo /> */}
          {header}
          <main>{this.props.children}</main>
        </div>
        <Offcanvas />
      </div>
    );
  }
}

export default TemplateWrapper;
